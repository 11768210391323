::-webkit-scrollbar {
  background-color: transparent;
  width: 0.75em;
  height: 0.4em;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  transform-style: preserve-3d;
  background-color: rgba(9, 11, 29, 0.8);
  border-radius: 50px;
  border-color: rgb(248 250 252);
  border-style: solid;
  border-width: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(9, 11, 29, 0.9);
}
::-webkit-scrollbar-thumb:active {
  background-color: rgba(9, 11, 29, 1);
}

/* Dark Mode */
.dark ::-webkit-scrollbar-track {
  background-color: rgb(15 23 42);
}
.dark ::-webkit-scrollbar-thumb {
  transform-style: preserve-3d;
  background-color: #58e4b6;
  border-radius: 50px;
  border-color: #090b1d;
  border-style: solid;
  border-width: 3px;
}
.dark ::-webkit-scrollbar-thumb:hover {
  background-color: #3acf9e;
}
.dark ::-webkit-scrollbar-thumb:active {
  background-color: #10b981;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
